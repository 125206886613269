// translatedNames.js

export const charaNames = {
  npc: "NPC",
  
  seha: "李世河",
  seulbi: "李雪菲",
  yuri: "徐維莉",
  j: "J",
  tein: "米斯特汀",
  ria: "伊莉雅",

  nata: "納塔",
  levia: "蕾比雅",
  harpy: "哈比",
  tina: "緹娜",
  violet: "薇歐莉特",

  wolfgang: "沃爾夫姜",
  luna: "露娜",
  soma: "索瑪",
  bai: "白",
  seth: "賽特",

  mirae: "未來",
  chulsoo: "金徹斯",
  eunha: "銀河",
  lucy: "露西",
  aeri: "愛里"
};

export const categoryNames = {
  unique: "輝煌時裝",
  rare: "稀有時裝",
  swim: "泳裝系列",
  single: "單立繪",
  normal: "一般時裝",
  "char-special": "角色專屬時裝",
  limited: "特別系列",
};

export const costumeNames = {
  "outrage_noir": "極惡闇影",
  "blue_dragon": "青龍映月",
  "cherry_blossom": "我的櫻花",
  nobleserenade: "貴族小夜曲", 
  demonicheartbeat: "惡魔悸動",
  officialinterview: "正式採訪", 
  moonnight: "月下的夜宴",
  "shining star": "閃耀之星", 
  solomon: "所羅門武裝",
  wot: "聖三之輪",
  hon: "黃泉使者",
  sod: "黑暗光輝",
  cybernetics: "模控科技",
  zw: "至高之翼",
  bw: "漆黑之翼",
  dragonian: "德拉格尼爾",
  "love_holic": "鬱鬱狂戀",
  "unlucky_chaser": "不幸追擊者",
  "alice-in-wonderland": "童話仙境",
  "april_cat_maid": "4月貓咪、男僕",
  "arcade": "街機女孩",
  "band": "交響低音號",
  "basic-style": "純粹風格",
  "bittersweet": "甜蜜&苦澀",
  "blackrose": "永恆黑玫瑰",
  "bloomingromance": "浪漫盛綻",
  "captain": "鋼鐵機動艦長",
  "ccangel": "棉花糖天使",
  "centum_cosplay": "楊秀妍/歐梅嘉騎士",
  "char-special": "角色專屬時裝",
  "cheerup": "啦啦隊長",
  "cheongsam": "旗袍馬褂",
  "cosplay_cafe": "換裝咖啡廳",
  "dearcrescent": "珍愛新月",
  "domestic": "執事&女僕",
  "dragon": "龍之氣息(龍息)",
  "eastghost": "東方亡者",
  "ensemble": "冰上的合奏",
  "evening": "舞會盛宴",
  "extreme": "極限滑板",
  "extreme_winter": "極限冬季",
  "fighting": "青春Fighting！",
  "flag": "賽車寶貝",
  "goodnight": "晚安小眠羊",
  "ground_division": "游擊師團",
  "heroes": "參戰英雄之輓歌",
  "holynight": "聖誕夜小兔",
  "hotspring": "暖和溫泉",
  "imperial": "帝國意志",
  "littledemon": "誘惑小惡鬼",
  "lolipop": "甜心棒棒糖",
  "midnight": "午夜十字軍",
  "miracleschool": "奇蹟少女",
  "misery": "悲慘深淵",
  "misookcostume": "紅蠍運動服",
  "morning": "懶洋洋襯衫",
  "mystic-shuffle": "博弈高手",
  "nano": "奈米戰鬥服",
  "navy": "海軍風格",
  "new_fasion": "新潮流",
  "nightmare": "魅力小惡魔",
  "nurse": "白衣天使&逃跑患者",
  "officelife": "職場生活",
  "on_time_railway": "準點鐵道",
  "passionate_ace": "熱血王牌",
  "picnicking": "花樣野餐",
  "pirate": "海盜",
  "pointcheck": "點綴方格",
  "princess": "雪原公主",
  "private_date": "私人約會",
  "rabbit": "野餐小兔兔",
  "ranger": "傳奇牛仔",
  "romance": "蔚藍領航員",
  "santa": "Xmas！天使叮叮噹",
  "school-sport": "辛強高中運動會",
  "school-swim": "校園泳裝",
  "school-uniforms": "辛強高中校園風春秋服",
  "seimei": "陰陽師",
  "serving": "打工吧！封印者",
  "snowfox": "毛茸茸雪狐",
  "bountyhunter": "賞金獵人",
  "space": "火星宇航員",
  "spymaster": "浪漫風衣",
  "starlight": "夢想星光",
  "straycat": "毛線喵喵",
  "street": "街頭嘻哈",
  "sunshine": "陽光下的誓言",
  "sweetpunk": "甜心龐克",
  "swim16": "海濱假日(2016泳裝)",
  "swim17": "海邊狂想曲(2017泳裝)",
  "swim18": "陽光海灘(2018泳裝)",
  "swim19": "夏日島嶼(2019泳裝)",
  "swim20": "盛夏的旋律(2020泳裝)",
  "swim21": "湛藍波浪(2021泳裝)",
  "swim22": "閃亮海洋(2022泳裝)",
  "swim23": "水槍盛典(2023泳裝)",
  "swim24": "夏日時光渡假風(2024泳裝)",
  "track_shooter": "逐風跑者",
  "trainingwolf": "訓練野狼",
  "troubleshooter": "小麻煩射手",
  "unionacademy": "UNION學院",
  "union_secret": "UNION特勤局",
  "urban": "風林火山",
  "water_park": "水上樂園",
  "wedding": "愛的誓言",
  "wedding_march": "婚禮進行曲",
  "wildowntown": "狂野街頭",
  "wind": "炫彩潮流",
  "wind_elf": "微風精靈",
  "winter": "冬戀物語",
  "wintercasual": "冬季日常",
  "yoho": "魅惑妖狐",
  "noblerose": "皇室玫瑰",
  "rockcat": "搖滾貓咪",
  "sweetrider": "甜心騎士",
  "icybutterfly": "冰凜夜蝶",
  "blackalice": "黑愛麗絲",
  "dreamgirl": "夢幻少女",
  "arcanajoker": "雅克那小丑",
  "prometheus": "普羅米修斯",
  "knivesshadow": "刀鋒月影",
  "originprayer": "原初之夢",
  "zodiacnight": "星座之夜",
  "devilishdream": "惡魔之夢", 
  "wannabeyourlove": "偷心",
  "blacksong": "闇歌女巫",
  "antique_m": "復古仕女",
  "miko": "祈願狐狸巫女&神官",
  "teardrop_waltz": "淚滴華爾滋",
  "fasion_influencer": "時尚潮流",
};
